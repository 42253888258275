// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover, Tooltip } from 'antd';
import { get, padStart } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import classnames from 'classnames';
import { getTeamUrl } from 'Common';
import styles from './Table.css';


import TableCellSelect from '../../../Components/TableCellSelect';

type Props = {
  projects: [],
  projectPhases: [],
  clientContacts: [],
  pagination: {},
  fetching: boolean,
  
  onChangeProjectField: Function,
  onChangeTable: Function
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      projects,
      projectPhases,
      clientContacts,
      pagination,
      fetching,

      onChangeProjectField,
      onChangeTable
    } = this.props;

    const columns = [

      { 
        title: 'Reference',
        key: 'id',
        width: 155,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <NavLink to={getTeamUrl(`projects/${record.id}/view`)}>
            { record.id }
          </NavLink>
        ),
      },
      
      {
        title: 'Client / Site',
        key: 'client.name',
        width: 252,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div className="ellipsis">
              <strong>
                { record.client_name }
              </strong>
            </div>
            <div className="ellipsis">
              { record.client_site_name }
            </div>
          </div>
        ),
      },
      
      { 
        title: ' ', 
        key: 'notes',
        width: 25,
        fixed: 'left',
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },
      
      {
        title: 'Contact',
        key: 'client_contact_id',
        width: 220,
        sorter: true,
        render: (text, record) => {

          const options = [
            { value: null, label: 'TBC' },
            ...clientContacts.filter(item => item.client_id === record.client_site.client_id).map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];
          
          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.client_contact_id}
              onChange={(value) => onChangeProjectField(record.id, 'client_contact_id', value)}
            />
          )
        }
      },

      {
        title: 'Start Date',
        key: 'start_date',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.start_date ? moment(record.start_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'End Date', 
        key: 'handover_date',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.handover_date ? moment(record.handover_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Contracts Managers',
        key: 'contracts_managers',
        width: 220,
        sorter: false,
        render: (text, record) => (
          <div>
            {record.contracts_manager ? <div>{`${record.contracts_manager.first_name} ${record.contracts_manager.last_name}`}</div> : ''}
            {record.other_contracts_manager ? <div>{`${record.other_contracts_manager.first_name} ${record.other_contracts_manager.last_name}`}</div> : ''}
          </div>
        ),
      },

      {
        title: 'Supervisor',
        key: 'supervisor_id',
        width: 220,
        sorter: false,
        render: (text, record) => get(record.supervisor, 'name'),
      },
      
      {
        title: 'Value',
        key: 'value_total',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_total).format('0,0.00')}`}
          </div>
        )
      },
      
      {
        title: 'Completed',
        key: 'value_completed',
        width: 180,
        sorter: true,
        render: (text, record) => `£ ${numeral(record.value_completed).format('0,0.0')}`
      },
      
      {
        title: 'Remaining',
        dataIndex: '',
        key: 'value_remaining',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.value_remaining	).format('0,0.00')}`}
          </div>
        )
      },
      
      {
        title: 'Target Cost',
        key: 'target_cost',
        width: 180,
        sorter: true,
        render: (text, record) => `£ ${numeral(record.target_cost).format('0,0.0')}`
      },
      
      {
        title: 'Actual Cost',
        key: 'sales_total',
        width: 180,
        sorter: true,
        render: (text, record) => `£ ${numeral(record.sales_total).format('0,0.0')}`
      },
      
      {
        title: 'Actual Cost Vs Completion',
        key: 'sales_total_completed_percentage',
        width: 180,
        sorter: true,
        render: (text, record) => record.sales_total_completed_percentage ? `${numeral(record.sales_total_completed_percentage).format('0,0.0')}%` : 'N/A'
      },
      
      {
        title: 'Actual Cost Vs Target',
        key: 'sales_total_target_percentage',
        width: 180,
        sorter: true,
        render: (text, record) => record.sales_total_target_percentage ? `${numeral(record.sales_total_target_percentage).format('0,0.0')}%` : 'N/A'
      },

      {
        title: 'Client Valuation Date',
        dataIndex: '',
        key: 'valuation_day',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.valuation_day && `${moment(`2020-01-${padStart(String(record.valuation_day), 2, '0')}`).format('Do')} of Month`}
          </div>
        )
      },

      {
        title: 'Last Valuation Date',
        dataIndex: '',
        key: 'last_valuation_date',
        width: 200,
        sorter: true,
        render: (text, record) => {
          const now = moment()
          const last = record.last_valuation_date ? moment(record.last_valuation_date) : null
          return (
            <div>
              {last && (now.diff(last, 'days') < 90 ? `${now.diff(last, 'days')} days ago` : moment(record.last_valuation_date).fromNow())}
            </div>
          )
        }
      },

      {
        title: 'Last Valuation Status',
        dataIndex: '',
        key: 'valuation_status',
        width: 200,
        render: (text, record) => record.latest_valuations.length !== 0 ? record.latest_valuations[0].status.title : null
      },
      
      {
        title: 'Status',
        key: 'status_key',
        width: 180,
        sorter: true,
        render: (text, record) => (
          <span>
            { record.status.title }
          </span>
        )
      },
      
      {
        title: 'Phase',
        key: 'phase_key',
        sorter: true,
        width: 180,
        onCell: () => ({
          className: styles.hasSelect,
        }),
        render: (text, record) => {

          const options = [];
          projectPhases.map(item => options.push({ value: item.key, label: item.title }))
          
          return (
            <TableCellSelect
              type="outlined"
              options={options}
              value={record.phase_key}
              onChange={(value) => onChangeProjectField(record.id, 'phase_key', value)}
            />
          )
        }
      },
      
      {
        title: 'Last Upload',
        key: 'last_upload_at',
        width: 200,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.last_upload_at ? moment(record.last_upload_at).fromNow() : '--'}
          </div>
        ),
      },

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) };

    const phaseColor =  {
      '1': 'tender',
      '5': 'preStart',
      '2': 'onSite',
      '3': 'statusComplete',
      '4': 'awaitingNextPhase'
    }

    return (
      <Table
        dataSource={projects}
        columns={columns} 
        rowKey={record => record.id}
        onRow={(record) =>  ({
            className: `disable-hover ${styles[phaseColor[record.phase.key]]}`
          })
        }
        scroll={scroll}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  };
}
